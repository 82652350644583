export enum EnvType {
  Development,
  Production,
  Staging,
}

export const ENV = EnvType.Staging;

export const ENV_VARIABLES = {
  [EnvType.Development]: {
    BASE_URL: 'https://qsk21yuaac.execute-api.us-east-1.amazonaws.com/Prod/',
    BASE_PROXY_URL: 'https://tproxy.ops.tsl-terls.cloud/apiv2/',
    // WS_URL: 'wss://0qrhosw6pe.execute-api.us-east-1.amazonaws.com/v1',
    WS_URL: 'wss://3kptdpnwk1.execute-api.us-east-1.amazonaws.com/v1',
    AWS_CONFIG: {
      Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_SWGJIlZhL',
        userPoolWebClientId: 'b2p8pkc8scv66jqadvvfqqnbb',
        clientId: 'b2p8pkc8scv66jqadvvfqqnbb',
        mandatorySignIn: true,
        oauth: {
          domain: 'https://tessellops1.auth.us-east-1.amazoncognito.com/login',
          scope: ['email', 'openid', 'profile'],
          redirectSignIn: 'http://localhost:3000/login',
          redirectSignOut: 'http://localhost:3000',
          responseType: 'code',
        },
      },
    },
    FRESHDESK_BASE_LINK: 'https://tessell-dev.freshdesk.com/a/tickets/',
    LEAKS: true,
  },
  [EnvType.Staging]: {
    BASE_URL: 'https://6d9rqmmc73.execute-api.ap-south-1.amazonaws.com/Prod/',
    BASE_PROXY_URL: 'https://tproxy.ops.tessell-stage.com/apiv2/',
    WS_URL: 'wss://q2k307dim0.execute-api.ap-south-1.amazonaws.com/v1/',
    AWS_CONFIG: {
      Auth: {
        region: 'ap-south-1',
        userPoolId: 'ap-south-1_sYsHvcByT',
        userPoolWebClientId: '3lp5h1m0eqeuc8i0jb9m4n8o7u',
        clientId: '3lp5h1m0eqeuc8i0jb9m4n8o7u',
        mandatorySignIn: true,
        oauth: {
          domain: 'https://opsuser.auth.ap-south-1.amazoncognito.com/login',
          scope: ['email', 'openid', 'profile'],
          redirectSignIn: 'http://localhost:3000/login',
          redirectSignOut: 'http://localhost:3000',
          responseType: 'code',
        },
      },
    },
    FRESHDESK_BASE_LINK: 'https://tessell-dev.freshdesk.com/a/tickets/',
    LEAKS: true,
  },
  [EnvType.Production]: {
    BASE_URL: 'https://i60iyh89of.execute-api.ap-south-1.amazonaws.com/Prod/',
    BASE_PROXY_URL: 'https://tproxy.ops.tessell.com/apiv2/',
    WS_URL: 'wss://btb51zsgck.execute-api.ap-south-1.amazonaws.com/v1',
    AWS_CONFIG: {
      Auth: {
        region: 'ap-south-1',
        userPoolId: 'ap-south-1_KpY6GGgjj',
        userPoolWebClientId: '7pmeqh1vti7idhg5jegiic9pj2',
        clientId: '7pmeqh1vti7idhg5jegiic9pj2',
        mandatorySignIn: true,
        oauth: {
          domain: 'https://opsuser.auth.ap-south-1.amazoncognito.com/login',
          scope: ['email', 'openid', 'profile'],
          redirectSignIn: 'http://localhost:3000/login',
          redirectSignOut: 'http://localhost:3000',
          responseType: 'code',
        },
      },
    },
    FRESHDESK_BASE_LINK: 'https://tessell.freshdesk.com/a/tickets/',
    LEAKS: true,
  },
  // [EnvType.TestingProd]: {
  //   BASE_URL: 'https://qsk21yuaac.execute-api.us-east-1.amazonaws.com/Prod/',
  //   BASE_PROXY_URL: 'https://tproxy.ops.tsl-terls.cloud/apiv2/',
  //   // WS_URL: 'wss://0qrhosw6pe.execute-api.us-east-1.amazonaws.com/v1',
  //   WS_URL: 'wss://3kptdpnwk1.execute-api.us-east-1.amazonaws.com/v1',
  //   AWS_CONFIG: {
  //     Auth: {
  //       region: 'us-east-1',
  //       userPoolId: 'us-east-1_xcekNbHT6',
  //       userPoolWebClientId: '32spst89lgqsnbl8sv18u1lp9q',
  //       clientId: '32spst89lgqsnbl8sv18u1lp9q',
  //       mandatorySignIn: true,
  //       oauth: {
  //         domain:
  //           'https://tessellops1033.auth.us-east-1.amazoncognito.com/login',
  //         scope: ['email', 'openid', 'profile'],
  //         redirectSignIn: 'http://localhost:3000/login',
  //         redirectSignOut: 'http://localhost:3000',
  //         responseType: 'code',
  //       },
  //     },
  //   },
  //   FRESHDESK_BASE_LINK: 'https://tessell-dev.freshdesk.com/a/tickets/',
  // },
};
